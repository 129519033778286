<br><br>
<div class="container">
  <div class="row">
    <div class="col-md-4  d-none d-md-block">
      <div class="crafts-card all-center" *ngIf='(selectedDistrict!== undefined) && districts[selectedDistrict].image'>
        <div class="craft-image">
          <img src="{{districts[selectedDistrict].image}}" alt="">
        </div>
        <div class="craft-content p-4">
          <h2 class="semibold-text">{{districts[selectedDistrict].name}}</h2>
          <ul>
            <li *ngFor='let craft of districts[selectedDistrict].crafts'>
              <h4 class="semibold-text">{{craft}}</h4>
            </li>
            <br>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <img style="max-width: 100%;" name="districtMap" src="../../../../assets/components/homepage/districtMap.png"
        usemap="#image-map" id="districtMap">

      <map name="image-map">
        <area target="" alt="Barmer" title="Click to explore Barmer" style="cursor: pointer;" id="barmerMap"
          coords="87,355,93,365,101,379,110,387,119,393,133,394,144,390,149,396,152,409,144,420,151,425,154,438,170,455,169,466,171,476,183,498,199,493,210,477,213,457,235,458,245,457,270,428,284,410,306,422,330,408,344,385,361,368,347,361,351,354,351,338,331,325,315,333,302,328,293,321,279,328,271,321,260,324,247,315,237,307,229,306,222,313,213,311,199,323,186,335,189,344,182,355,176,346,160,345,159,339,142,342,131,338,113,344,92,340"
          shape="poly">
        <area target="" alt="Jodhpur" title="Click to explore Jodhpur" style="cursor: pointer;" id="jodhpurMap"
          coords="469,325,450,307,443,278,432,270,407,268,398,264,392,246,391,236,392,226,392,218,382,217,374,210,364,209,351,199,343,192,333,194,323,201,310,210,303,204,293,198,291,190,282,194,264,209,270,229,287,245,293,266,289,279,293,295,290,317,313,327,332,322,350,334,352,350,351,361,359,366,367,372,378,364,388,358,399,352,408,355,422,355,428,360,441,351,447,362,453,360,459,362,457,348,460,340"
          shape="poly">
        <area target="" alt="Bikaner" title="Click to explore Bikaner" style="cursor: pointer;" id="bikanerMap"
          coords="363,195,378,210,388,213,394,218,411,238,418,227,431,217,431,201,444,193,458,200,469,194,469,167,463,154,452,151,449,138,452,128,456,115,469,111,477,112,490,110,488,101,491,84,491,72,498,67,491,55,493,43,488,35,486,45,478,37,467,35,448,31,419,32,409,35,396,57,398,68,389,72,372,77,358,73,348,62,342,54,328,65,321,82,314,99,309,111,290,122,284,143,277,150,279,154,291,159,298,169,298,182,308,187,322,187,314,191,309,199,316,203,322,196,333,191,342,186,350,190"
          shape="poly">
        <area target="" alt="Jaisalmer" title="Click to explore Jaisalmer" style="cursor: pointer;" id="jaisalmerMap"
          coords="96,181,99,169,111,153,124,139,139,131,151,133,154,139,154,151,164,159,167,169,179,169,190,169,198,167,203,163,213,161,222,159,233,156,248,159,267,151,284,159,292,172,297,185,307,191,301,197,287,187,270,197,260,207,262,219,262,230,272,238,284,255,286,281,283,305,286,317,277,321,260,320,247,308,240,303,226,306,211,311,180,331,181,350,161,340,147,335,128,338,104,340,96,336,103,320,104,300,90,295,81,293,68,290,61,282,53,276,39,268,41,255,42,240,49,226,52,218,59,216,69,201,84,192"
          shape="poly">
      </map>
    </div>
  </div>
</div>
<br><br>