<div class="container">
  <mdb-breadcrumb>
    <mdb-breadcrumb-item *ngFor='let breadcrumb of breadCrumbList; let i = index'
      (click)='handleBreadCrumb(breadcrumb, i)' [class.active]='(i+1)===breadCrumbList.length'>
      <h4>{{breadcrumb.name}}</h4>
      
    </mdb-breadcrumb-item>
  </mdb-breadcrumb>
  <div *ngIf='selectedList && selectedList.length'>
    <div class="row">
      <div class="col-md-4" *ngFor='let category of selectedList | paginate: { itemsPerPage: 9, currentPage: p };'>
        <div class="card m-4" (click)='breadCrumbList.push(category); handleCategories(category)'
          [style.background-image]="'url('+category.image+')'">
          <h4 class="all-center semibold-text white-text">{{category.name}}</h4>
        </div>
      </div> 
    </div>
    <div class="text-center">
      <pagination-controls autoHide="true" (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
  <div *ngIf='gallery && gallery.length'>
    <div class="row">
      <div class="col-md-4"
        *ngFor='let image of gallery | paginate: { itemsPerPage: 9, currentPage: p }; let i = index;'>
        <div class="card m-4" [style.background-image]="'url('+image.url+')'" (click)='launchModal(i)'>
          <h4 class="all-center semibold-text white-text">{{image.title}}</h4>
        </div>
      </div>
    </div>
    <br>
    <div class="text-center">
      <pagination-controls autoHide="true" (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
</div>

<!-- <ngx-image-viewer *ngIf='gallery && imageIndex' [src]="gallery" [(index)]="imageIndex"></ngx-image-viewer> -->

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-fluid m-0" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button> -->
        <ngx-image-viewer *ngIf='images' [src]="images" [config]='imgViewerConfig' [(index)]="imageIndex"
          (customEvent)="handleViewerEvent($event)">
        </ngx-image-viewer>
      </div>
    </div>
  </div>
</div>