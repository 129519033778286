import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.css']
})
export class ProductSearchComponent {
  @Output() searchChange = new EventEmitter<string>();

  searchTerm: string = '';

  onSearchChange() {
    this.searchChange.emit(this.searchTerm);
  }
}
