import { Component, OnInit } from '@angular/core';
declare function instafeed(): void;
declare function hello(): void;
@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {
  
  myScriptElement: HTMLScriptElement;
  constructor() { 
    // this.myScriptElement = document.createElement("script");
    // this.myScriptElement.src = "https://cdn.jsdelivr.net/gh/stevenschobert/instafeed.js@2.0.0rc1/src/instafeed.min.js";
    // document.body.appendChild(this.myScriptElement);
    
    
  }

  ngOnInit(): void {
    instafeed();
    hello();
  }
  

}
