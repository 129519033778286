<div class="holder p-4 d-flex flex-column" (click)="onClicked()">
  <div class="header">
    <h2 class="text-center semibold-text mb-2">{{name}}</h2>
    <p class="mb-1 text-center">Order #{{orderId}} - <span [style.color]="getStatusColor(orderStatus)">
          {{capFirstLetter(orderStatus)}}</span></p>
  </div>
  <div class="d-flex mt-auto">
    <span>Quantity: {{quantity}}</span>
    <span class="price-text ml-auto">{{currencySymbol}} {{price}}</span>
  </div>
</div>
