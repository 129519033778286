import { Component, EventEmitter, Output } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { AuthorizationService } from '../../../services/authorization.service';


@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['./navbar-top.component.scss']
})
export class NavbarTopComponent {

  @Output() hamburger = new EventEmitter<any>();
  

  constructor(
    public authService: AuthorizationService,
    public navService: NavService
    ) { }

    clickFunction() {
      console.log("clicked me!");
    }

}
