import {Component} from '@angular/core';
import {AuthorizationService} from 'src/app/services/authorization.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {

  isYoutube: boolean;
  isAtHome: boolean;
  currentPage: string;
  constructor(
    private authService: AuthorizationService
  ) {
    this.isYoutube = false;
    this.isAtHome = true;
    this.currentPage = "PICTURES";
  }
  handleAtHome(input: boolean){
    this.isAtHome = input;
  }
  StoriesShowForVideos(){
    if(this.currentPage == "PICTURES"){
      this.isAtHome = true;
    }
    this.currentPage = "VIDEOS";
  }
  StoriesShowForPictures(){
    if(this.currentPage == "VIDEOS"){
      this.isAtHome = true;
    }
    this.currentPage = "PICTURES";
  }
}