<mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
    <mdb-carousel-item *ngFor='let slide of slides'>
      <div class="container-fluid image-container des-heigth" [ngStyle]="{ 'background-image': 'url(' + slide.image + ')'}">
        <div class="container" >
          <div class="card bg-transparent p-4" style="border: none!important;">
            <h2 *ngIf='!authService.isHindi' class="semibold-text cl-white align-right">{{slide.name}}</h2>
            <h2 *ngIf='authService.isHindi' class="semibold-text cl-white align-right">{{slide.nameHindi}}</h2>
            
            <h4 *ngIf='!authService.isHindi' class="regular-text cl-white align-right">{{slide.subHeading}}</h4>
            <h4 *ngIf='authService.isHindi' class="regular-text cl-white align-right">{{slide.subHeadingHindi}}</h4>
            <button type="button" style="margin-left: auto; margin-right: 0px;" mdbBtn color="danger" mdbWavesEffect
              (click)='authService.navigateToPage(slide.link)'>LEARN MORE</button>
          </div>
        </div>
      </div>
    </mdb-carousel-item>

    <mdb-carousel-item *ngFor='let tour of tours'>
      <div class="container-fluid image-container des-heigth" [ngStyle]="{ 'background-image': 'url(' + tour.image + ')'}">
        <div class="container" >
          <div class="card bg-transparent p-4" style="border: none!important;">
            <h2 *ngIf='!authService.isHindi' class="semibold-text cl-white align-right">{{tour.title.english}}</h2>
            <h2 *ngIf='authService.isHindi' class="semibold-text cl-white align-right">{{tour.title.hindi}}</h2>
            
            <h4 *ngIf='!authService.isHindi' class="regular-text cl-white align-right">{{tour.subHeading}}</h4>
            <h4 *ngIf='authService.isHindi' class="regular-text cl-white align-right">{{tour.subHeadingHindi}}</h4>
            <button type="button" style="margin-left: auto; margin-right: 0px;" mdbBtn color="danger" mdbWavesEffect
              (click)='authService.navigateToPage(tour.link)'>LEARN MORE</button>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>

<!-- 
  Write similar in style.scss to achive border radius design
  @media only screen and (min-width: 1200px) {
    app-homepage-destinations-carousel,app-tours-carousel,app-art-craft-carousel{
    .carousel-inner
    {
      width: auto!important;
      margin: 0px 50px!important;
      border-radius: 10px!important;
    }
    }
  } -->