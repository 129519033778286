<!-- <app-banner [banner]='banner'></app-banner> -->
<app-tours-carousel [slides]="tours"></app-tours-carousel>

<!-- Intro here -->
<div class="container">
  <div class="text-center m-4">
    <h2 class="red-text semibold-text py-4">
      {{ authService.isHindi ? intro.title.hindi : intro.title.english }}
    </h2>
    <p class="regular-text" style="font-weight: 600">
      {{ authService.isHindi ? intro.content.hindi : intro.content.english }}
    </p>
  </div>
</div>

<br /><br />

<!-- collaborations ref -->
<br /><br />
<div class="container">
  <div class="collaboartions">
    <div id="music&tours">
      <div class="card" *ngFor="let tour of music_tours" style="margin-top: 3rem">
        <div class="row">
          <div class="py-3 col-md-6">
            <img class="col image" src="{{ tour.image }}" alt="" />
          </div>
          <div class="col-md-6 my-3 py-5">
            <div class="mx-4">
              <h4 class="red-text semibold-text py-2">
                {{
                  authService.isHindi ? tour.title.hindi : tour.title.english
                }}
              </h4>
              <div class="regular-text" style="font-weight: 600" [innerHTML]="
                  authService.isHindi ? tour.intro.hindi : tour.intro.english
                "></div>
              <button type="button" class="mob-res-btn" style="
                  margin-left: auto;
                  text-transform: none;
                  margin-right: 0px;
                  margin-top: 1rem;
                  padding: 0.1rem 1rem;
                  border-radius: 1.7rem;
                " mdbBtn color="danger" mdbWavesEffect (click)="authService.navigateToPage(tour.link)">
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="craft&tours">
      <div class="card" *ngFor="let tour of craft_tours" style="margin-top: 3rem">
        <div class="row">
          <div class="py-3 col-md-6">
            <img class="col image" src="{{ tour.image }}" alt="" />
          </div>
          <div class="col-md-6 my-3 py-5">
            <div class="mx-4">
              <h4 class="red-text semibold-text py-2">
                {{
                  authService.isHindi ? tour.title.hindi : tour.title.english
                }}
              </h4>
              <div class="regular-text" style="font-weight: 600" [innerHTML]="
                  authService.isHindi ? tour.intro.hindi : tour.intro.english
                "></div>
              <button type="button" class="mob-res-btn" style="
                  margin-left: auto;
                  text-transform: none;
                  margin-right: 0px;
                  margin-top: 1rem;
                  padding: 0.1rem 1rem;
                  border-radius: 1.7rem;
                " mdbBtn color="danger" mdbWavesEffect (click)="authService.navigateToPage(tour.link)">
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="dance&tours">
      <div class="card" *ngFor="let tour of dance_tours" style="margin-top: 3rem">
        <div class="row">
          <div class="py-3 col-md-6">
            <img class="col image" src="{{ tour.image }}" alt="" />
          </div>
          <div class="col-md-6 my-3 py-5">
            <div class="mx-4">
              <h4 class="red-text semibold-text py-2">
                {{ authService.isHindi ? tour.title.hindi : tour.title.english }}
              </h4>
              <div class="regular-text" style="font-weight: 600" [innerHTML]="
                authService.isHindi ? tour.intro.hindi : tour.intro.english
              "></div>
              <button type="button" class="mob-res-btn" style="
                margin-left: auto;
                text-transform: none;
                margin-right: 0px;
                margin-top: 1rem;
                padding: 0.1rem 1rem;
                border-radius: 1.7rem;
              " mdbBtn color="danger" mdbWavesEffect (click)="authService.navigateToPage(tour.link)">
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="festival&tours">
      <div class="card" *ngFor="let tour of festival_tours" style="margin-top: 3rem">
        <div class="row">
          <div class="py-3 col-md-6">
            <img class="col image" src="{{ tour.image }}" alt="" />
          </div>
          <div class="col-md-6 my-3 py-5">
            <div class="mx-4">
              <h4 class="red-text semibold-text py-2">
                {{ authService.isHindi ? tour.title.hindi : tour.title.english }}
              </h4>
              <div class="regular-text" style="font-weight: 600" [innerHTML]="
                authService.isHindi ? tour.intro.hindi : tour.intro.english
              "></div>
              <button type="button" class="mob-res-btn" style="
                margin-left: auto;
                text-transform: none;
                margin-right: 0px;
                margin-top: 1rem;
                padding: 0.1rem 1rem;
                border-radius: 1.7rem;
              " mdbBtn color="danger" mdbWavesEffect (click)="authService.navigateToPage(tour.link)">
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<br /><br />

<app-collaborations></app-collaborations>
