<div class="container-fluid text-center background">

  <p class="regular-text">a collabration of UNESCO and Rajasthan Tourism</p>

  <div class="row">
    <div class="col-sm-4"></div>
    <div class="col-sm-4">
      <ul class="list-inline">
        <li class="list-inline-item" >
          <img class="navbara-top-image" src="../../../../assets/components/common/unesco_logo.png" alt="" >
        </li>
        <li class="list-inline-item">
          <img class="navbara-top-image" src="../../../../assets/components/common/rajasthan_logo.png" alt="" >
        </li>
      </ul>
    </div>
    <div class="col-sm-4" >
      
    </div>
  </div>
</div>
