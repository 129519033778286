import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private navService: NavService, private http: HttpClient) {}

  getProducts(): Promise<any[]> {
    return this.navService.getWCJson('products?per_page=100')
      .then((res: any[]) => {
        if (!res || !res.length) {
          return [];
        }
        return res.map(item => item);
      });
  }

  getCrafts(): Observable<any[]> {
    return this.http.get<any[]>('https://cms.rajasthansafar.com/wp-json/acf/v3/arts_crafts?per_page=1000');
  }

  getArtists(): Observable<any[]> {
    return this.http.get<any[]>('https://cms.rajasthansafar.com/wp-json/acf/v3/artists?per_page=1000');
  }

}
