import { Component, OnInit, Input } from '@angular/core';
import {AuthorizationService} from 'src/app/services/authorization.service';
import {NavService} from 'src/app/services/nav.service';


@Component({
  selector: 'app-art-craft-carousel',
  templateUrl: './art-craft-carousel.component.html',
  styleUrls: ['./art-craft-carousel.component.scss']
})
export class ArtCraftCarouselComponent implements OnInit {
@Input() slides:{ image: string}[];

  constructor(public authService: AuthorizationService) { 
    
  }

  ngOnInit(): void {
  }


}
