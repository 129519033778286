<div class="banner-container">
  <div class="view overlay zoom">
    <img src="{{banner?.image}}" alt="zoom placeholder">
    <div class="mask waves-effect waves-light text-center">
      <div class="container all-center">
        <h1 class="white-text semibold-text">
          {{banner.caption}}
        </h1>
      </div>
    </div>
  </div>
  <img src="../../../../assets/components/common/grain_overlay.png" alt="" class="white-grain">
</div>
