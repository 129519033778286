import { Component, OnInit } from '@angular/core';
import {AuthorizationService} from 'src/app/services/authorization.service';
import {NavService} from '../../../services/nav.service';

@Component({
  selector: 'app-homepage-tours-carousel',
  templateUrl: './homepage-tours-carousel.component.html',
  styleUrls: ['./homepage-tours-carousel.component.scss']
})
export class HomepageToursCarouselComponent implements OnInit {

  tours: { id: number, title: { english: string, hindi: string }, location: { english: string, hindi: string }, intro: { english: string, hindi: string }, image: string }[];
  carouselConfig = {
    slidesToShow: 4,
    infinite: false,
    responsive: [{
      breakpoint: 1024,
      settings: {slidesToShow: 2}
    }, {
      breakpoint: 768,
      settings: {slidesToShow: 1}
    }]
  };

  constructor(public authService: AuthorizationService) {
    this.getTours();
  }


  getTours() {
    this.authService.getReq('tours?per_page=100').subscribe((data: any[]) => {
      this.tours = data.map((tour: any) => {
        return {
          id: tour.id,
          title: {
            english: tour.acf.name.english,
            hindi: tour.acf.name.hindi
          },
          location: {
            english: tour.acf.location.english,
            hindi: tour.acf.location.hindi
          },
          intro: {
            english: this.authService.trimWords(tour.acf.carousel_intro.english, 30),
            hindi: this.authService.trimWords(tour.acf.carousel_intro.hindi, 30)
          },
          image: tour.acf.home_page_carousel_cover_image
        }
      });
    });
  
  }

  trackBy() {
  }

  ngOnInit(): void {
  }
}
