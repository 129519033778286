<div class="mx-auto root">
  <div class="embed-responsive embed-responsive-1by1" (click)="this.itemClick.emit()">
    <img src="{{src}}" alt="{{title}}" class="embed-responsive-item image-main">
  </div>

  <div class="d-flex w-100 p-3 bottom">
    <div>
      <p class="semibold-text mt-auto mb-0 title" (click)="this.itemClick.emit()">{{title}}</p>
      <p *ngIf="desc" class="mb-0">{{desc}}</p>
    </div>

  </div>
</div>
