import { Component, OnInit, Input } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
@Component({
  selector: 'app-tours-carousel',
  templateUrl: './tours-carousel.component.html',
  styleUrls: ['./tours-carousel.component.scss']
})
export class ToursCarouselComponent implements OnInit {
  
  @Input() slides:  { id: number, title: { english: string, hindi: string }, location: { english: string, hindi: string }, intro: { english: string, hindi: string }, image: string, link: string }[];
  
  constructor(
    public authService: AuthorizationService
  ) { 
  
  }

  ngOnInit(): void {
  }
 

}
