import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-homepage-upper-section',
  templateUrl: './homepage-upper-section.component.html',
  styleUrls: ['./homepage-upper-section.component.scss']
})
export class HomepageUpperSectionComponent {
  homeContent: {banner_image: string, banner_image_caption: string, banner_image_caption_hindi:string, content: string, content_hindi: string, title: string, title_hindi: string};
  constructor(
    public authService: AuthorizationService
  ) { 
    this.getHomeIntro()
  }

  getHomeIntro() {
    this.authService.getReq('pages/324').subscribe((data: any) => {
      this.homeContent = {banner_image: data.acf?.banner_image, banner_image_caption: data.acf?.banner_image_caption, banner_image_caption_hindi: data.acf?.banner_image_caption_hindi, content: data.acf?.content, content_hindi: data.acf?.content_hindi, title: data.acf?.title, title_hindi: data.acf?.title_hindi};
    })
  }
}
