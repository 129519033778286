
<app-homepage-destinations-carousel></app-homepage-destinations-carousel>
<app-homepage-upper-section></app-homepage-upper-section>
<app-homepage-tours-carousel></app-homepage-tours-carousel>
<app-homepage-journey-carousel></app-homepage-journey-carousel>
<div id="destinations">
  <app-homepage-map></app-homepage-map>
</div>
<app-playlist-carousel></app-playlist-carousel>
<app-card-overlay-carousel *ngIf='featuredVillages?.length' [slides]='featuredVillages'>
</app-card-overlay-carousel>
<div id="arts&crafts">
  <app-homepage-art-craft-cards></app-homepage-art-craft-cards>
</div>


<div class="bg-grey" *ngIf='events && events.length'>
  <app-events-gallery [events]='events'></app-events-gallery>
</div>
<br>
<div class="bg-red notification" *ngIf="notifications?.length">
  <ngx-marquee [duration]="duration"
  [pauseOnHover]="true">
    <mark style="color: white ; background-color: #8b0000;" >{{notifi}}</mark>
  </ngx-marquee>
</div>
<br>
<br>
<app-instagram></app-instagram>
<app-safar-app-ad></app-safar-app-ad>

<br>