import { Component, OnInit } from '@angular/core';
import {AuthorizationService} from 'src/app/services/authorization.service';
import { SafeResourceUrl} from '@angular/platform-browser'

@Component({
  selector: 'app-gallery-stories',
  templateUrl: './gallery-stories.component.html',
  styleUrls: ['./gallery-stories.component.scss']
})
export class GalleryStoriesComponent implements OnInit {
  embedlinks: SafeResourceUrl[];
  constructor(
    private authService: AuthorizationService
  ) { 
    this.embedlinks = [
      "twUBeELO1jFemw",
      "qQUhGSu2_iR1fA",
      "YAUxHbfg_k0gNA"
    ].map((link) =>
      this.authService.sanatizeUrl(
        `https://embed.culturalspot.org/embedv2/story/${link}`
      )
    );
  }

  ngOnInit(): void {
  }

}