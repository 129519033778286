<div *ngIf="userService.userIsArtist" class="container pt-4">
  <h1 class="mb-5">Enquiries for your products</h1>
  <div *ngFor="let order of orders; let i = index;">

    <hr *ngIf="i > 0"/>

    <!-- So, yeah, then I ended up writing most of the logic in the template. -->
    <h2 class="mt-4 py-2 mb-0">Order ID: #{{order.id}} -
      <span class="semibold-text status p-1 mr-3" [class.selected]="order.status === 'pending'"
            [class.new]="order.newStatus === 'pending'"
            (click)="order.newStatus = order.status !== 'pending' ? 'pending' : undefined"
            [style.color]="getStatusColor('pending', order.status)">Pending</span>

      <span class="semibold-text status p-1 mr-3" [class.selected]="order.status === 'processing'"
            [class.new]="order.newStatus === 'processing'"
            (click)="order.newStatus = order.status !== 'processing' ? 'processing' : undefined"
            [style.color]="getStatusColor('processing', order.status)">Processing</span>

      <span class="semibold-text status p-1 mr-3" [class.selected]="order.status === 'completed'"
            [class.new]="order.newStatus === 'completed'"
            (click)="order.newStatus = order.status !== 'completed' ? 'completed' : undefined"
            [style.color]="getStatusColor('completed', order.status)">Complete</span>

      <mdb-icon *ngIf="!order.processing && order.newStatus && order.status !== order.newStatus" fas icon="check"
                class="p-1 status-icon" (click)="setStatus(order)"></mdb-icon>
      <span *ngIf="order.processing" class="spinner-border mt-1" role="status"></span>
    </h2>

    <div>
      Name: <span class="ml-2 mr-3 primary-text">{{order.customerDetails.name}}</span>
      <div style="display: inline-block">
        Email: <span class="ml-2 mr-3 primary-text">{{order.customerDetails.email}}</span>
        Phone: <span class="ml-2 primary-text">{{order.customerDetails.phone}}</span>
      </div>
    </div>
    <div class="row mt-3 mb-4">
      <div *ngFor="let item of order.items" class="my-3 col-md-4 col-sm-6">
        <app-order-order [currencySymbol]="order.currencySymbol" [id]="item.id" [name]="item.name"
                         [price]="item.price" [quantity]="item.quantity"></app-order-order>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!userService.userIsArtist" class="mb-5 container error">
  <h2>This page is only for artists</h2>
</div>
