<div class="filter-list-wrapper">
  <div class="filter-list">
    <div class="filter-list-item">
      <label  for="category">Category</label>
      <select class="form-control" id="category" [(ngModel)]="selectedCategory" (change)="onFilterChange()">
        <option value="">All</option>
        <option *ngFor="let category of categories" [value]="category">{{ category }}</option>
      </select>
    </div>
    <div class="filter-list-item">
      <label for="artist">Artist</label>
      <select class="form-control" id="artist" [(ngModel)]="selectedArtist" (change)="onFilterChange()">
        <option value="">All</option>
        <option *ngFor="let artist of artists" [value]="artist.id">{{ artist.name }}</option>
      </select>
    </div>
    <div class="filter-list-item">
      <label for="craft">Craft</label>
      <select class="form-control" id="craft" [(ngModel)]="selectedCraft" (change)="onFilterChange()">
        <option value="">All</option>
        <option *ngFor="let craft of crafts" [value]="craft.id">{{ craft.name }}</option>
      </select>
    </div>
  </div>
  <div class="filter-list-item">
    <label for="">Search</label>
    <input class="form-control" type="text" [(ngModel)]="searchTerm" (input)="onFilterChange()" placeholder="Search by title">
  </div>
  
</div>

  