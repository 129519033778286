<div class="container">
  <br>
  <div class="text-center my-4">
    <h2 class="semibold-text red-text">PRODUCTS</h2><br>
  </div>
  <br>

  <div class="product-listing-section">
    <div class="product-listing-filters">
      <app-product-filter (filterChange)="onFilterChange($event)"></app-product-filter>
    </div>
    <div *ngIf="isLoading" class="loading-spinner">
      Loading...
    </div>
  
    <div *ngIf="!isLoading" class="product-listing-wrapper">
      <div class="products-row">
        <div *ngFor="let product of displayedProducts" class="product-single-item">
          <app-product-card [src]="product.images[0].src" [title]="product.name" [desc]="product.price" [id]="product.id" [type]="type">
          </app-product-card>
        </div>
      </div>
      <app-pagination
        [totalItems]="filteredProducts.length"
        [currentPage]="currentPage"
        [itemsPerPage]="itemsPerPage"
        (pageChange)="onPageChange($event)">
      </app-pagination>
  
    </div>
  
  </div>

</div>

