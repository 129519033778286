import { Component, OnInit } from '@angular/core';
import { ProductService } from './products-data';

@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.css']
})

export class ProductListingComponent implements OnInit {
  products: any[] = [];
  filteredProducts: any[] = [];
  displayedProducts: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 12;
  isLoading: boolean = false;
  type: 'artist';

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    this.fetchProducts();
  }

  fetchProducts() {
    this.isLoading = true;
    this.productService.getProducts().then(data => {
      this.products = data;
      this.filteredProducts = data;
      this.updateDisplayedProducts();
      this.isLoading = false;
    });
  }

  onFilterChange(filters: any) {
    this.filteredProducts = this.products.filter(product => {
      const matchesCategory = filters.category ? product.categories.some(c => c.name === filters.category) : true;
      const matchesArtist = filters.artist ? product.acf.artist.toString() === filters.artist : true;
      const matchesCraft = filters.craft ? product.acf.craft.toString() === filters.craft : true;
      const searchInName = filters.search ? product.name.toLowerCase().includes(filters.search.toLowerCase()) : true;
      return matchesCategory && matchesArtist && matchesCraft && searchInName;
    });
    this.currentPage = 1;
    this.updateDisplayedProducts();
  }

  onSearchChange(searchTerm: string) {
    this.filteredProducts = this.products.filter(product => {
      const searchInName = product.name.toLowerCase().includes(searchTerm.toLowerCase());
      //const searchInDescription = product.acf.description.english.toLowerCase().includes(searchTerm.toLowerCase());
      //return searchInName || searchInDescription;
    });
    this.currentPage = 1;
    this.updateDisplayedProducts();
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.updateDisplayedProducts();
  }

  updateDisplayedProducts() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedProducts = this.filteredProducts.slice(startIndex, endIndex);
  }
}