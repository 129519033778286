import { Component, OnInit, Input } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import {NavService} from 'src/app/services/nav.service';
@Component({
  selector: 'app-homepage-destinations-carousel',
  templateUrl: './homepage-destinations-carousel.component.html',
  styleUrls: ['./homepage-destinations-carousel.component.scss']
})
export class HomepageDestinationsCarouselComponent implements OnInit {

  slides: { name: string, nameHindi: string, subHeading: string, subHeadingHindi: string,image: string}[];
  tours: { id: number,  title: { english: string, hindi: string }, location: { english: string, hindi: string }, intro: { english: string, hindi: string }, image: string, link: string, featured_on_homepage: boolean }[];
  constructor(
    public authService: AuthorizationService
  ) {
    this.getDistricts();
    this.getTours();
   }

  ngOnInit(): void {
    
  }

  getDistricts() {
    this.authService.getReq('districts').subscribe((dists: any[]) => {
      this.slides = dists.map((dist: any) => {
          return {
          name: dist.acf.name,
          subHeading: dist.acf.sub_heading,
          nameHindi: dist.acf.name_hindi,
          subHeadingHindi: dist.acf.sub_heading_hindi,
          image: dist.acf.banner_image,
          link: '/district/' + dist.id
        }
      }).filter((dist) => dist);
      
    })
  }



getTours() {
  this.authService.getReq('tours?per_page=100').subscribe((data: any[]) => {
    this.tours = data.map((tour: any) => {
      if(tour.acf.featured_on_homepage)return {
        id: tour.id,
        title: {
          english: tour.acf.name.english,
          hindi: tour.acf.name.hindi
        },
        location: {
          english: tour.acf.location.english,
          hindi: tour.acf.location.hindi
        },
        intro: {
          english: this.authService.trimWords(tour.acf.carousel_intro.english, 30),
          hindi: this.authService.trimWords(tour.acf.carousel_intro.hindi, 30)
        },
        image: tour.acf.caoursel_image,
        link: '/tour/' + tour.id,
        featured_on_homepage: tour.acf.featured_on_homepage
      
    }
    }).filter((tour) => tour);
  });
}

}
