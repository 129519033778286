import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { ProductService } from './products-data';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.css']
})
export class ProductFilterComponent implements OnInit {
  @Output() filterChange = new EventEmitter<any>();

  categories: any[] = [];
  artists: any[] = [];
  crafts: any[] = [];
  productCrafts: any[] = [];
  productArtists: any[] = [];
  selectedCategory: any = '';
  selectedArtist: any = '';
  selectedCraft: any = '';
  searchTerm: string = '';

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    this.productService.getProducts().then(data => {
      this.extractFilters(data);
      this.productService.getArtists().subscribe(data => {
        this.extractArtists(data);
      });
      this.productService.getCrafts().subscribe(data => {
        this.extractCrafts(data);
      });
    });
  }

  extractCrafts(crafts: any[]) {
    const craftsSet = new Set<any>();
    crafts.forEach(craft => {
      if(this.productCrafts.includes(craft.id.toString())){
        craftsSet.add({"id" : craft.id, "name": craft.acf.name})
      }
    });
    this.crafts = Array.from(craftsSet);
  }

  extractArtists(artists: any[]) {
    const artistsSet = new Set<any>();
    console.log(this.productArtists);
    artists.forEach(artist => {
      console.log(artist.id.toString());
      if(this.productArtists.includes(artist.id.toString())){
        artistsSet.add({"id" : artist.id, "name": artist.acf.name})
      }else{
        //artistsSet.add({"id" : artist.id, "name": artist.acf.name})
      }
    });
    this.artists = Array.from(artistsSet);
  }

  extractFilters(products: any[]) {
   const categoriesSet = new Set<any>();
   const artistsSet = new Set<any>();
   const craftsSet = new Set<any>();

    products.forEach(product => {
      product.categories.forEach(category => categoriesSet.add(category.name));
      if (product.acf && product.acf.artist) {
        artistsSet.add(product.acf.artist.toString());
      }
      if (product.acf && product.acf.craft) {
        craftsSet.add(product.acf.craft.toString());
      }
    });

    this.categories = Array.from(categoriesSet);
    this.productArtists = Array.from(artistsSet);
    this.productCrafts = Array.from(craftsSet);
  }

  onFilterChange() {
    this.filterChange.emit({
      category: this.selectedCategory,
      artist: this.selectedArtist,
      craft: this.selectedCraft,
      search: this.searchTerm
    });
  }
}
