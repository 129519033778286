import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-safar-app-ad',
  templateUrl: './safar-app-ad.component.html',
  styleUrls: ['./safar-app-ad.component.scss']
})
export class SafarAppAdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
