import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Mapboxgl from 'mapbox-gl';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { NavService } from 'src/app/services/nav.service';
// import { link } from 'fs';

@Component({
  selector: 'app-homepage-map',
  templateUrl: './homepage-map.component.html',
  styleUrls: ['./homepage-map.component.scss'],
})
export class HomepageMapComponent implements OnInit {
  districts: {
    name: string;
    nameHindi: string;
    link: string;
    coordinate: { langitude: string; latitude: string };
  }[];
  Villages: {
    name: string;
    nameHindi: string;
    link: string;
    coordinate: { langitude: string; latitude: string };
  }[];
  description: '<strong></strong><p>Truckeroo brings dozens of food trucks, live music, and games to half and M Street SE (across from Navy Yard Metro Station) today from 11:00 a.m. to 11:00 p.m.</p>';
  map: Mapboxgl.Map;
  constructor(public authService: AuthorizationService) {
    this.getDistricts();
    this.getVillages();
  }

  ngOnInit(): void {
    Mapboxgl.accessToken = environment.mapboxkey;
    this.map = new Mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [73.5784629, 26.7538274],
      zoom: 6.5,
    });
  }
  
  //marker for map
  createMarker(lang: number, lat: number, col: string, vill_dist: string,link: string) {
    const current = this;
    const marker2 = new Mapboxgl.Marker({ color: col })
      .setLngLat([lang, lat])
      .addTo(this.map);

    // marker2.getElement().addEventListener('mouseover', function (e) {
    //   this.popUpHover(lang, lat, vill);
    // });
    marker2.getElement().addEventListener('click', function (e) {
      current.popUpClick(lang, lat, vill_dist,link);
    });


    
    // marker2.getElement().addEventListener('click', function (e) {
    //   e.preventDefault();
    // });
  }

  //functions for popups
  popUpClick(lang: number, lat: number, vill_dist: string,link: string) {
    console.log('Marker Clicked');
    this.map.getCanvas().style.cursor = 'pointer';

    this.customPopUp
      .setLngLat([lang, lat])
      .setHTML(
        '<div class="card p-1"><h2 class="semibold-text red-text" style="text-align:center;">'+vill_dist+'</h2><a _ngcontent-ell-c118="" type="button" mdbbtn="" color="danger" mdbwaveseffect="" href="'+link+'"  ng-reflect-color="danger" class="map-btn-col btn">LEARN MORE</a><a _ngcontent-ell-c118="" type="button" mdbbtn="" color="danger" mdbwaveseffect="" href="https://www.google.com/maps/search/?api=1&query= '+lat+','+lang+'" target="_blank"  ng-reflect-color="danger" class="map-btn-col btn">DIRECTION</a></div>'
      )
      .addTo(this.map);
  }

  popUpHover(lang: number, lat: number, vill_dist: string) {
    console.log('Marker hover');
    this.map.getCanvas().style.cursor = 'pointer';

    this.popup
      .setLngLat([lang, lat])
      .setHTML('<div class="marker-popup">' + vill_dist + '</div>')
      .addTo(this.map);
  }

  //poppus for map
  popup = new Mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  customPopUp = new Mapboxgl.Popup({
    // anchor: 'bottom', // To show popup on top
    // offset: { bottom: [0, -10] }, // To prevent popup from over shadowing the marker.
    closeOnClick: false // To prevent close on mapClick.
  });

  getDistricts() {
    this.authService.getReq('districts').subscribe((dists: any[]) => {
      this.districts = dists
        .map((dist: any) => {
          return {
            name: dist.acf.name,
            nameHindi: dist.acf.name_hindi,
            link: '/district/' + dist.id,
            coordinate: {
              langitude: dist.acf.coordinate.longitude,
              latitude: dist.acf.coordinate.latitude,
            },
            marker: this.createMarker(
              dist.acf.coordinate.longitude,
              dist.acf.coordinate.latitude,
              'red',
              dist.acf.name,
              '/district/' + dist.id
            ),
          };
        })
        .filter((dist) => dist);
    });
  }

  getVillages() {
    this.authService.getReq('villages').subscribe((villages: any[]) => {
      this.Villages = villages
        .map((village: any) => {
          return {
            name: village.acf.name,
            link: '/village/' + village.id,
            nameHindi: village.acf.namehindi,
            coordinate: {
              langitude: village.acf.gmap_location.longitude,
              latitude: village.acf.gmap_location.latitude,
            },
            marker: this.createMarker(
              village.acf.gmap_location.longitude,
              village.acf.gmap_location.latitude,
              'black',
              village.acf.name,
              '/village/' + village.id
            ),
          };
        })
        .filter((village) => village);
    });
  }
}
