<div class="bg-red">
    <div class="container mob-res-h" >
        <div class="d-inline-flex pad">
            <img class="mr-2 mob-res-h" src="../../../../assets/components/homepage/sfar-desert.webp" alt="" style="height: 20rem;">
            <div style="position: relative; display: flex; justify-content: center; align-items: center;">
                <div class="p-4 mob-res-p">
                    <h4 class="regular-text mob-res-text">Safar desert is a one-stop solution for people, who want to know about the cultural tourism destinations of western Rajasthan (Jaisalmer, Jodhpur, Barmer and Bikaner districts) and the creative offerings of the rural locales. The app enlists destination-wise handicraft and performing artists along with their contact details and cultural products, which enables users to directly get in touch with them. The app also</h4>
                    <div class="d-inline-flex align-items-center">
                        <p style="font-size: 1.2rem;font-weight: 600;" class="mob-res-text2">DOWNLOAD</p>
                       <a href="https://apps.apple.com/gb/app/safar-desert/id1669315857" target="_blank"><img class="logo" src="../../../../assets/components/downloads/apple.png" alt=""></a>  
                      <a href="https://play.google.com/store/apps/details?id=com.safardesert&hl=en&pli=1" target="_blank"><img class ="logo" src="../../../../assets/components/downloads/android.png" alt=""></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>