<div class="container text-center" style="margin-top: 20px ;">
  <img style= "height: 100px;" src="../../../../assets/images/common/logo_placeholder.png" alt="">
  
  <div class="m-4">
    <h4 style="font-weight:600;" *ngIf='!authService.isHindi'>{{homeContent?.title}}</h4>
    <h4 style="font-weight: 600;" *ngIf='authService.isHindi'>{{homeContent?.title_hindi}}</h4>
    <p class="regular-text" *ngIf='!authService.isHindi'>{{homeContent?.content}}</p>
    <p class="regular-text" *ngIf='authService.isHindi'>{{homeContent?.content_hindi}}</p>
    
  </div>
</div>



