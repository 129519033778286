<div class="container pad">
  <h4 class="red-text semibold-text pad" style="font-weight: 800;">INSTAGRAM</h4>
  <a href="https://www.instagram.com/rajasthan_safar/" target="_blank" style="color: #000;">
    <div class="d-inline-flex pad mob-mb-1">
      <img class="mr-2 insta-logo" src="../../../../assets/images/common/SafarInstaLogo.jpg" alt="">
      <div style="position: relative; display: flex; justify-content: center; align-items: center;">
        <div class="mob-res-p">
          <h4 class="regular-text" style="font-weight: 800;">rajasthan_safar</h4>
          <div class="d-inline-flex align-items-center">
            <p style="font-weight: 600;">Showcasing Art, Festival and Artistes of
              Rajasthan</p>
          </div>
        </div>
      </div>

    </div>

  </a>




  <div class="row">

    <div id="instafeed-container" class="instafeed"></div>

  </div>
</div>
<div style="margin-bottom: 15px;"></div>
