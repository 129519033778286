<div class="container">
  <div class="text-center my-4">
    <br>
    <h2 class="semibold-text red-text">MEDIA</h2><br>
  </div>

  <!-- Prints here -->

  <div *ngIf='prints?.length'>
    <h2 class="name-card regular-text">PRINT</h2>
    <div class="row">
      <div class="col-md-4 my-4" *ngFor='let print of prints | paginate: { id: "print-pagination", itemsPerPage: 6, currentPage: p }'>
        <div class="card">
          <div class="image" [ngStyle]="{ 'background-image': 'url(' + print.link + ')'}"></div>
          <div class="text p-2">
            <h4 class="regular-text" *ngIf='!authService.isHindi'>{{print.title}}</h4>
            <h4 class="regular-text" *ngIf='authService.isHindi'>{{print.titleHindi}}</h4>
            <ul class="list-inline m-0">
              <li class="list-inline-item">
                <p class="regular-text" *ngIf='!authService.isHindi'>{{print.author}}</p>
                <p class="regular-text" *ngIf='authService.isHindi'>{{print.authorHindi}}</p>
              </li>
              <li class="list-inline-item" style="float: right;">
                <p class="regular-text red-text" style="cursor: pointer;" (click)='triggerModal(print)'>Read More..</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <br><br>
    <div class="text-center">
      <pagination-controls id="print-pagination" autoHide="true" (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>

  <br><br>
  <!-- Electronics here -->
  <h2 class="name-card regular-text">ELECTRONIC</h2>
  <div class="text-center" *ngIf='electronics?.length'>
    <div class="row">
      <div class="col-md-6" *ngFor='let electronic of electronics | paginate: { id: "electronics-pagination", itemsPerPage: 6, currentPage: q }'>
        <div class="video-container my-4">
          <div class="embed-responsive embed-responsive-4by3">
            <iframe class="embed-responsive-item" [src]='electronic'></iframe>
          </div>
        </div>
      </div>
    </div>
    <br><br>
    <pagination-controls id="electronics-pagination" autoHide="true" (pageChange)="q = $event"></pagination-controls>
  </div>
</div>

<div mdbModal #mediaModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myeventsModalLabel"
  aria-hidden="true" style="overflow-y: auto;">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <button type="button" class="close pull-right" aria-label="Close" (click)="mediaModal.hide()">
          <span aria-hidden="true" class="cross">×</span>
        </button>
        <h4 class="regular-text" id="myModalLabel" *ngIf='data' style="width: fit-content; margin: auto;">{{data.title}}
        </h4>
      </div>
      <div class="modal-body">
        <div class="container" *ngIf='data' style="text-align: center;">
          <a href="{{data.ext_link}}">
            <h4>{{data.ext_link}}</h4>
          </a>
          <img src="{{data.picture}}" alt="" class="img-fluid">
          <br><br>
          <h4 *ngIf='data.author' class="regular-text">by {{data.author}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>