import { Component, OnInit, Input } from '@angular/core';
import {AuthorizationService} from 'src/app/services/authorization.service';

@Component({
  selector: 'app-district-carousel',
  templateUrl: './district-carousel.component.html',
  styleUrls: ['./district-carousel.component.scss']
})
export class DistrictCarouselComponent implements OnInit {
  @Input() slides:{ image: string}[];
  constructor(public authService: AuthorizationService) { }

  ngOnInit(): void {
  }

}
