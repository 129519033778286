<div
  *ngIf="tours && tours.length"
  class="container-fluid bg-white mt-5 py-5 products"
>
  <div class="container p-0">
    <h2 class="text-center mb-5 semibold-text red-text">TOURS</h2>
    <!-- <ngx-slick-carousel class="mt-4 mx-4" [config]="carouselConfig">
        <div  ngxSlickItem *ngFor="let tour of tours; trackBy: trackBy" class="product"
          (click)="authService.navigateToPage('tours')">
          <div class="card bg-white  ">
            
              <img src="{{tour.image}}" alt="" class="abs fit embed-responsive-item">
            

          </div>
        </div>
      </ngx-slick-carousel> -->

    <ngx-slick-carousel class="mt-4 mx-4" [config]="carouselConfig">
      <a href="/tours#music&tours">
      <div ngxSlickItem class="product">
        <div class="card bg-white">
          <img
            src="../../../../assets/images/common/music_tourism.png"
            alt=""
            class="abs fit embed-responsive-item"
          />
        </div>
      </div>
    </a>

    <a href="/tours#craft&tours">
      <div ngxSlickItem class="product">
        <div class="card bg-white">
          <img
            src="../../../../assets/images/common/craft_tourism.png"
            alt=""
            class="abs fit embed-responsive-item"
          />
        </div>
      </div>
    </a>
      <a href="/tours#dance&tours">
      <div ngxSlickItem class="product">
        <div class="card bg-white">
          <img
            src="../../../../assets/images/common/dance_tourism.png"
            alt=""
            class="abs fit embed-responsive-item"
          />
        </div>
      </div>
    </a>
      <a href="/tours#festival&tours">
      <div ngxSlickItem class="product">
        <div class="card bg-white">
          <img
            src="../../../../assets/images/common/festivals.png"
            alt=""
            class="abs fit embed-responsive-item"
          />
        </div>
      </div>
    </a>
    </ngx-slick-carousel>
  </div>
</div>
