<div *ngIf="tour" class="container">
  <div class="row mt-5">
    <div class="col-lg-8 col-md-12 p-0 banner-img">
      <img src="{{tour.banner_image}}" alt="" class="col p-0"/>
      <mdb-icon *ngIf="tourIdx > 0" fas icon="chevron-left" class="left" (click)="changeTour(false)"></mdb-icon>
      <mdb-icon *ngIf="tourIdx < tours.length - 1" fas icon="chevron-right" class="right" (click)="changeTour(true)">
      </mdb-icon>
    </div>
    <div class="col-lg-4 col-md-12 bg-red p-5 d-flex flex-column">
      <div class="my-auto">
        <h2>{{authService.isHindi ? tour.name.hindi : tour.name.english}}</h2>
        <p>{{authService.isHindi ? tour.carousel_intro.hindi : tour.carousel_intro.english}}</p>
      </div>
    </div>
  </div>

  <div class="text-center mt-4">
    <h2 class="red-text semibold-text py-4">{{authService.isHindi ? 'विवरण' : 'DESCRIPTION'}}</h2>
    <p>{{authService.isHindi ? tour.description.hindi : tour.description.english}}</p>
  </div>

  <div class="text-center mt-4">
    <h2 class="red-text semibold-text py-4">{{authService.isHindi ? 'ITINERARIES' : 'ITINERARIES'}}</h2>

    <div *ngFor="let iten of tour.itenaries" class="row p-1 my-4 iten-holder">
      <img src="{{iten.image}}" alt="" class="col-lg-4 col-md-8 p-0 mx-auto">
      <div class="col-lg-8 col-md-12 p-4">
        <h4 class="text-left semibold-text red-text">{{authService.isHindi ? iten.title.hindi : iten.title.english}}
        </h4>
        <p class="mt-3 text-left">{{authService.isHindi ? iten.description.hindi : iten.description.english}}</p>
      </div>
    </div>
  </div>

  <div *ngIf="tour.videos" class="text-center mt-5">
    <h2 class="red-text semibold-text py-4 mt-3">
      {{authService.isHindi ? 'VIDEOS OF THE EXPERIENCES' : 'VIDEOS OF THE EXPERIENCES'}}</h2>

    <ngx-slick-carousel [config]="carouselConfig" class="mx-4">
      <div ngxSlickItem *ngFor="let vid of tour.videos; trackBy:trackBy" class="mx-3">
        <div class="embed-responsive embed-responsive-4by3">
          <iframe class="embed-responsive-item" [src]="vid.video" allowfullscreen></iframe>
        </div>
        <p class="semibold-text mt-3">{{ authService.isHindi ? vid.title.hindi : vid.title.english}}</p>
      </div>
    </ngx-slick-carousel>

  </div>
</div>

<div *ngIf="tour" class="container-fluid my-5 px-5">
  <h2 class="text-center red-text semibold-text py-4 mt-3">{{authService.isHindi ? 'GALLERY' : 'GALLERY'}}</h2>
  <div class="row gallery">
    <img src="{{tour.gallery.left_image}}" alt="" class="col-lg-3">
    <div class="container-fluid col-lg-4 center">
      <img src="{{tour.gallery.center_top_image}}" alt="" class="col-12">
      <img src="{{tour.gallery.center_bottom_image}}" alt="" class="col-12">
    </div>
    <img src="{{tour.gallery.right_image}}" alt="" class="col-lg-5">
  </div>
</div>

<div class="container">
  <h2 class="text-center red-text semibold-text py-4 mt-3">
    {{authService.isHindi ? 'ASK US FOR MORE DETAILS' : 'ASK US FOR MORE DETAILS'}}</h2>


  <div class="form-container row">
    <div class="col-md-4 pl-0 pr-1">
      <input type="text" class="form-control" placeholder="Your Name" mdbInput mdbValidate
             [formControl]="contactFormName">
    </div>
    <div class="col-md-4 px-1">
      <input type="email" class="form-control" placeholder="Your Email" mdbInput mdbValidate
             [formControl]="contactFormEmail">
    </div>
    <div class="col-md-4 pl-1 pr-0">
      <input type="text" class="form-control" placeholder="Phone No." mdbInput mdbValidate
             [formControl]="contactFormPhone">
    </div>

    <div class="form-group col-md-12 p-0 mt-2">
      <textarea class="form-control formMsg" rows="3" placeholder="Your Message" mdbInput mdbValidate
                [formControl]="contactFormMessage"></textarea>
    </div>
    <button mdbBtn color="danger" block="true" (click)='sendMail()'
            [disabled]="validatingForm.status=='INVALID' || formSubmitted">{{formSubmitted ? 'SUBMITTED' : 'INQUIRE'}}
    </button>
  </div>


</div>
