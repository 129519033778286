<div class="container">
  <br>
  <div class="text-center my-4">
    <h2 class="semibold-text red-text">GALLERY</h2><br>
    <ul class="list-inline selector my-4">
      <li class="list-inline-item left" [class.active]='!isYoutube' (click)='isYoutube = false; StoriesShowForPictures()'>
        <h4 class="regular-text">PICTURES</h4>
      </li>
      <li class="list-inline-item right" [class.active]='isYoutube' (click)='isYoutube = true; StoriesShowForVideos()'>
        <h4 class="regular-text">VIDEOS</h4>
      </li>
    </ul>
  </div>
  <br>

  <div style="min-height: 385px;">
    <app-gallery-pictures *ngIf='!isYoutube' (isAtHome)='handleAtHome($event)'></app-gallery-pictures>
    <app-gallery-videos *ngIf='isYoutube' (isAtHome)='handleAtHome($event)'></app-gallery-videos>
  </div>

  <br><br>
  <app-playlist-carousel></app-playlist-carousel>
  <br><br>

  <div class="text-center mini-b-4 mini-t-6" *ngIf='isAtHome'>
    <ul class="list-inline selector my-4">
      <li class="list-inline-item active">
        <h4 class="regular-text">STORIES</h4>
      </li>
      
    </ul>
  </div>

  

  <app-gallery-stories *ngIf='isAtHome'></app-gallery-stories>
  

</div>