<!-- <app-banner [banner]='banner'></app-banner> -->
<div class="container whats-new m-auto">
  <div class="m-4 text-center">
    <h2 class="semibold-text red-text">WHAT'S NEW</h2>
    <p class="regular-text">This section will feature the newest tour packages, the product innovations and the artist
      recognition in mainstream media. Keep looking this space for update.
    </p>
  </div>
</div>

<div class="container">
  <br><br>
  <div class="collaboartions">
    <mdb-carousel [animation]="'slide'" class="mb-4">
      <mdb-carousel-item *ngFor='let update of updates'>
        <div class="card">
          <div class="row">
            <div class="col-md-6 image" [style.background-image]="'url(' + update.image +')'"></div>
            <div class="col-md-6">
              <div class="m-4">
                <h4 class="red-text semibold-text">{{ authService.isHindi ? update.title.hindi : update.title.english }}
                </h4>
                <p class="semibold-text">{{ authService.isHindi ? update.subtitle.hindi : update.subtitle.english }}
                </p>
                <div class="regular-text mt-2" [innerHTML]="authService.isHindi ? update.details.hindi : update.details.english">
                </div>
                <br><br>
                <button type="button" *ngIf="update.link && update.link.link" mdbBtn color="danger" mdbWavesEffect
                  (click)='handleLink(update.link)'>Know
                  More</button>
              </div>
            </div>
          </div>
        </div>
      </mdb-carousel-item>
    </mdb-carousel>
  </div>
</div>

<div *ngIf="products && products.length" class="container-fluid bg-grey mt-5 py-5 products">
  <div class="container p-0">
    <h2 class="text-center mb-5 semibold-text red-text">INNOVATIONS: NEW PRODUCTS</h2>
    <ngx-slick-carousel class="mt-4 mx-4" [config]="carouselConfig">
      <div ngxSlickItem *ngFor="let product of products; trackBy: trackBy" class="product"
        (click)="authService.navigateToPage('explore/product/' + product.id)">
        <div class="bg-white p-2 col-md-11 mx-auto">
          <div class="embed-responsive embed-responsive-1by1">
            <img src="{{product.image}}" alt="" class="p-0 embed-responsive-item">
          </div>
          <h4 class="mt-3 text-center red-text">{{authService.isHindi ? product.title.hindi : product.title.english}}
          </h4>
        </div>
      </div>
    </ngx-slick-carousel>
    <div class="text-center mt-5 mb-5">
      <button class="bg-red py-2 px-2 border-none" style="border: 0;" type="button" (click)='authService.navigateToPage("latest-products")'>View All Products</button>
    </div>
  </div>
</div>

<div *ngIf="spotlights" class="spotlight p-5 mb-5">
  <div class="container p-0">
    <h2 class="text-center mb-4 semibold-text red-text">SPOTLIGHT</h2>

    <mdb-carousel [animation]="'slide'">
      <mdb-carousel-item *ngFor='let spotlight of spotlights'>
        <div class="row">

          <div class="col-lg-5 col-md-6 mx-auto my-4">
            <div class="embed-responsive embed-responsive-4by3">
              <img *ngIf="spotlight.media.type === 'Image'" src="{{spotlight.media.image}}" alt=""
                class="embed-responsive-item">
              <iframe *ngIf="spotlight.media.type === 'Youtube'" class="embed-responsive-item"
                [src]="spotlight.media.youtube_link" allowfullscreen></iframe>
            </div>
          </div>

          <div class="col-lg-7 col-md-12 pl-5 my-auto">
            <h4 class="mb-2 red-text semibold-text">
              {{authService.isHindi ? spotlight.title.hindi : spotlight.title.english}}</h4>
            <div class="regular-text" [innerHTML]="authService.isHindi ? spotlight.details.hindi : spotlight.details.english"></div>
          </div>
        </div>
      </mdb-carousel-item>
    </mdb-carousel>
  </div>
</div>

<div *ngIf='events && events.length'>
  <app-events-gallery [events]='events'></app-events-gallery>
</div>
