<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="mt-4 col-md-12 col-lg-3 text-center">
      <a target="_blank" href="https://www.instagram.com/rajasthan_safar/">
        <div class="social-icon">
          <i class="fab fa-instagram"></i>
        </div>
      </a>
      <iframe src="https://www.powr.io/instagram-feed/u/3dfca81f_1587392545" width="400" height="500"
              frameborder="0"></iframe>
    </div>

    <div class="col-9 col-md-6 col-xl-4 p-0 d-flex flex-column flex-lg-row safar-tunes-holder">
      <img src="assets/components/homepage/app-safar-tunes.jpg" alt="Safar Tunes image"
           class="d-none d-lg-block img-portrait"/>
      <img src="assets/components/homepage/app-safar-tunes-long.jpg" alt="Safar Tunes image"
           class="d-block d-lg-none img-land"/>
      <div class="p-4 w-100 d-flex flex-column contents">
        <h2>Safar Tunes</h2>
        <p class="mb-3">SAFAR Tunes takes you abuzz with the folk melodies of Langa, Manganiyar and Mir musicians of
          Rajasthan. Immerse yourself on a musical journey with the mystic desert musicians. Music tourism trails are
          being developed by the Department of Tourism, Govt of Rajasthan in collaboration with UNESCO.</p>
        <div class="mt-auto d-flex flex-wrap justify-content-center">
          <button type="button" mdbBtn mdbWavesEffect
                  (click)="openLink('https://play.google.com/store/apps/details?id=bnc.safartunes.app')">
            <a href="https://play.google.com/store/apps/details?id=bnc.safartunes.app" target="_blank"
               class="semibold-text">
              DOWNLOAD ANDROID APP</a>
          </button>
          <button type="button" mdbBtn mdbWavesEffect
                  (click)="openLink('https://apps.apple.com/gb/app/safar-tunes/id1543663991')">
            <a href="https://apps.apple.com/gb/app/safar-tunes/id1543663991" target="_blank" class="semibold-text">
              DOWNLOAD iOS APP</a>
          </button>
        </div>
      </div>
    </div>

    <div class="mt-4 col-md-12 col-lg-3 text-center">
      <a target="_blank" href="https://www.facebook.com/ich.safar/">
        <div class="social-icon mb-4">
          <i class="fab fa-facebook-f"></i>
        </div>
      </a>
      <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fich.safar&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=419266588753145"
          width="360" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
          allowTransparency="true" allow="encrypted-media"></iframe>
    </div>
  </div>
</div>
