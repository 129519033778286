import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { NgxMarqueeModule } from 'ngx-marquee';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent {
  
  
  notifications: string[];
  notifi: string;
  duration: string;
  count = -1;
  
  featuredVillages: { name: string, nameHindi: string, subHeading: string, subHeadingHindi: string, content: string, image: string, link: string }[];
  events: { date: string, image: string }[];
  banner: { image: string, caption: string };

  constructor(
    private _authService: AuthorizationService
  ) {
    this.getFeaturedVillages();
    this.getEvents();
    this.getNotifications();
    this.banner = { image: '../../../../assets/components/common/banner_image.jpg', caption: 'Showcasing Art, Festival and Artistes of Rajasthan' };
  }

  ngOnInit(): void {}

  setNextCycle(): void {
    this.count = (this.count + 1) % this.notifications?.length;
    this.notifi = this.notifications[this.count];
    const theTime = this.notifi?.length / 5 * 400;
    this.duration = `${theTime}ms`;
    setTimeout(() => {
      this.setNextCycle();
    }, theTime);
  }

  getFeaturedVillages() {
    this._authService.getReq('villages').subscribe((villages: any[]) => {
      this.featuredVillages = villages.map((village: any) => {
        if (village.acf.feature_on_homepage) return {
          name: village.acf.name,
          subHeading: village.acf.sub_heading,
          content: village.acf.homepage_intro,
          image: village.acf.homepage_image,
          link: '/village/' + village.id,
          nameHindi: village.acf.namehindi,
          subHeadingHindi: village.acf.subheadinghindi,
          contentHindi: village.acf.carousel_introhindi
        }
      }).filter((village) => village);
      window.scrollTo({ top: 0 });
    })
  }

  getEvents() {
    this._authService.getReq('events').subscribe((events: any[]) => {
      this.events = events.map((event: any) => {
        if (Date.now() < Date.parse(event.acf.end_date)) {
          return {
            date: event.acf.date,
            image: event.acf.cover_picture,
            id: event.id
          }
        }
      }).filter((event) => event);
    });
  }

  getNotifications() {
    const now = new Date();
    this._authService.getReq("notifications?per_page=50").subscribe((nots: any[]) => {
      this.notifications = nots.map(not => {
        const notDate = not?.acf?.valid_date;
        if (notDate?.length) {
          if (now <= new Date(notDate)) return not?.acf?.title
        } else {
          return not?.acf?.title;
        }
      });
      this.setNextCycle();
    });
  }

}
